@import "../../assets/theme/vars";

.wrapper_users {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .box_actions {
    .btn {
      min-width: auto !important;
      padding: 0 1rem;
    }
    .dropdown-item a:hover {
      color: $white_text;
    }
    .btn-secondary {
      &:active,
      &:focus {
        background: var(--primary_color) !important;
      }
    }
  }
  .box_tool_user {
    .form_inline {
      display: flex;
      justify-content: flex-end;
      .form-control {
        border-radius: 5px 0 0 5px;
      }
    }
    .box_search_inp {
      .btn {
        background: #d1d6df;
        min-width: auto;
        height: calc(1.5em + 0.75rem + 2px);
        border-radius: 0 5px 5px 0;
        padding: 0.375rem 0.75rem;
      }
    }
  }
}
.box_avatar_user_profile {
  .avatar_profile{
    margin: 0 auto;
    img{
      border-radius: 50%;
      object-fit: cover;
      width: 40px !important;
      height: 40px !important;
    }
  }
}
.wrapper_users_details {
  @include bggrey;
  min-height: calc(100vh - 4rem);
}

