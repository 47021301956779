@import "../../assets/theme/vars";

.sec__provisional{
  h2{
    text-align: center;
    max-width: 600px;
    padding: 4rem 1rem;
    letter-spacing: 8px;
    margin: 0 auto;
    margin-top: 5rem;
    border: 5px dashed var(--primary_color);
    border-radius: 1rem;
  }
}


.wrapper_jobbank {
  @include bggrey;
  min-height: calc(100vh - 4rem);

  .contain_gral_jobbank {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }

  .sec_catalog_listjobs {
    width: 100%;
    .box_searching_jobs {
      padding: 1rem 1.5rem 1.5rem;
      background: #222222;
      border-radius: 0.5rem;
      color: #f2f2f2;
      form {
        .form-group {
          margin-bottom: 0;
        }
      }
      .btn {
        min-width: auto;
        width: 100%;
        border-color: #ffffff;
        color: #ffffff;
      }
    }
    .nav-tabs {
      border-bottom: 2px solid #cccccc;
    }
    .cont_box_items_jobs {
      margin-top: 2rem;
      .options_order {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0.75rem;
      }
    }
    .box_aside_hidde {
      display: none;
    }
  }
  .sec_category_jobs {
    max-width: 265px;
    width: 100%;
    padding: 0 0.25rem;
    .box_items_aside {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      .tags_item_category_jobs {
        min-width: auto;
        margin-bottom: 0.25rem;
        border: 1.5px solid #444;
        color: #333;
        height: 30px;
        padding: 0.15rem 0.7rem;
        font-size: 0.8rem;
        &:hover {
          border: 1.5px solid var(--primary_color);
          background: var(--primary_color);
          color: #ffffff;
        }
      }
    }
  }
  .page-link {
    color: #333;
  }
}

.card_shadows {
  background: #ffffff;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.sec_ttls_jobbank {
  .content_ttls_jobbank {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content_btns_job {
      min-width: 445px;
      .btn {
        position: relative;
        margin-left: 0.5rem;
        min-width: auto;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        &.secundary {
          padding-top: 0.4rem;
          span {
            display: block;
            line-height: 1.1;
          }
          .min {
            font-size: 0.75rem;
          }
        }
        .dots_num {
          position: absolute;
          width: 25px;
          height: 25px;
          top: -6px;
          right: -9px;
          // background: #1b3978;
          background: #333;
          border: 4px solid #f5f5f5;
          color: #fff;
          // box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.15);
          text-align: center;
          padding-top: 3px;
          font-size: 0.73rem;
          border-radius: 50%;
        }
      }
    }
  }
  .content_ttls_line {
    margin-top: 1rem;
    .ttls {
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        background: #303030;
        z-index: 0;
        top: 45%;
        left: 0;
      }
      span {
        background-color: #f2f2f2;
        position: relative;
        z-index: 1;
      }
    }
  }
}

.box_items_jobs {
  .item_card_jobs {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 1rem;
    .card_info_jobs {
      width: 100%;
      .name_vacant {
        line-height: 1.1;
        min-height: 50px;
        margin-bottom: 0;
      }
      .publication_date {
        color: #bbbbbb;
      }
      .description_job {
        margin-top: 2rem;
      }
    }
    .card_options {
      text-align: right;
      max-width: 120px;
      width: 100%;
      img {
        max-height: 70px;
        margin-bottom: 1rem;
      }
      .box_salary {
        margin-bottom: 1rem;
        p {
          font-size: 0.9rem;
        }
        .monto_salary {
          letter-spacing: -1px;
          font-size: 1.5rem;
        }
      }
      .btn {
        min-width: auto;
        padding: 0.5rem 0;
        width: 100%;
        line-height: 1;
        margin-bottom: 0.5rem;
        font-size: 0.95rem;
      }
    }
  }
}
.special .box_items_jobs .item_card_jobs {
  margin-top: 4.5rem !important;
}
.wrapper_detailsjobs {
  position: relative;
  .box_items_jobs {
    .item_card_jobs {
      margin-top: 1.5rem;
      flex-direction: column;
      .card_options {
        max-width: 100% !important;
        text-align: left;
        img {
          position: absolute;
          top: 105px;
          right: 20px;
        }
        .box_btns_actions_item {
          position: absolute;
          top: 28px;
          right: 0;
          display: flex;
          width: 50%;
          gap: 5px;
        }
      }
    }
  }
}

.wrapper_applicant_form {
  width: 100%;
  .content_applicant_form {
    max-width: 550px;
    margin: 0 auto;
    .sec_form_applicant {
      margin-top: 1rem;
      .content_application {
        .info_business_applicant {
          text-align: center;
          background: #f5f5f5;
          padding: 0.75rem 1rem;
          border-radius: 0.5rem;
          margin-bottom: 1rem;
          p {
            font-size: 0.9rem;
            color: #999999;
            margin-bottom: 0;
          }
        }
        padding: 1.25rem 2rem;
        .info_user_applicant {
          p {
            margin: 0;
            // margin-bottom: .15rem;
            color: #999999;
          }
        }
        @media(max-width: 768px) {
          padding: .5rem;
        }
      }
      .content_application_sent {
        max-width: 500px;
        margin: 0 auto;
        padding: 2rem 1rem;
        .ico-sent {
          img {
            max-height: 125px;
          }
        }
        .box_info_note {
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
          .ico_note {
            min-width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.75rem;
          }
        }
        .bx_application {
          margin-top: 3rem;
        }
      }
    }
  }
}

.wrapper_form {
  .content_shadows_form {
    padding: 2rem;
    padding-top: 1rem;
    border-radius: 0.75rem;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.15);
    background: #fff;
    .goback {
      justify-content: flex-end;
      max-width: 100%;
    }
    .form-control-sm {
      height: calc(1.5em + 0.75rem + 2px) !important;
      font-size: 1rem;
    }
    .isHidde {
      overflow: hidden;
    }
    .box_picture {
      text-align: center;
      img {
        max-height: 80px;
      }
    }
    .input-group-text {
      min-width: 100px;
    }
    .box_info_save {
      h6 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          max-height: 30px;
        }
      }
    }
    .box_pay_sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ddd;
      margin-top: 1em;
      padding-top: 2.4rem;
      .dinfotex {
        text-align: center;
        img {
          max-height: 40px;
        }
        div {
          font-size: 0.6rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: bold;
        }
      }
    }
  }
}

.input-group-text.amount {
  min-width: 35px !important;
}

.wrapper_reg_services {
  .wrapper__editor_text {
    background: #f8f8f8;
    .public-DraftStyleDefault-block {
      padding: 0 1rem;
    }
  }
}
